.navbar {
    height: 6vh;
    background: var(--dgreen, #002626);
    display:flex;
    justify-content: center;
    align-items: center;
    width: 680px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 60px; /* Adjust the top value to move it lower */
    right: 105px; /* Adjust the left value to move it horizontally */
    z-index: 2; /* Adjust the z-index as needed to control stacking order */
}
  
  .nav-items {
    display: flex;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: flex-end;
    margin-right: 2rem;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 13vh;
  }
  
  .nav-item a {
    font-family: Ubuntu Mono;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    margin-right: 2rem;
    padding: 6px 16px;
    border-radius: 5px;
  }
  
  .nav-item a:hover {
    color: #95C623;
  }

  .logo-container {
    position: absolute;
    top: -145px; /* Adjust the top value to move it lower */
    left: 100px; /* Adjust the left value to move it horizontally */
  }

  .logo-container img {
    width: 500px; /* Make the image 50% smaller */
  }

