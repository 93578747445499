.services-submenu {
    width: 10rem;
    position: absolute;
    top: 60px;
    left: 140px;
    list-style: none;
    text-align: start;
  }
  
  .services-submenu li {
    background: #002626;
    cursor: pointer;
    font-family: 'Ubuntu Mono', monospace;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    box-sizing: border-box; /* Include padding and borders in the total width and height */
  }
  
  .services-submenu li a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: white;
    padding: 16px;
    box-sizing: border-box; /* Include padding and borders in the total width and height */
    border-radius: 0; /* Set border-radius to 0 to have sharp corners */
  }
  
  .services-submenu li:first-child:hover a {
    background: #43ABFF;
    color: white;
  }
  
  .services-submenu li:nth-child(n+2):hover a {
    background: #BD7F46;
    color: white;
  }
  
  .services-submenu li:nth-child(n+3):hover a {
    background: #BACED2;
    color: black;
  }
  
  .services-submenu li:nth-child(n+4):hover a {
    background: #E7BC4D;
    color: black;
  }
  
  .services-submenu li:nth-child(n+5):hover a {
    background: #507A8B;
    color: white;
  }
  
  .services-submenu.clicked {
    display: none;
  }
  
  .submenu-item {
    text-decoration: none;
  }
  